import React, { useState, useEffect } from "react";
import { FieldType, GroupType, SectionType, SectionProps } from "../../types";
import Field from "../field";
import { sectionConfig } from "../../config";
import { ReactComponent as Trash } from "../../images/trash.svg";

const Section: React.FC<SectionProps> = ({
  section,
  setSections,
  sections,
}) => {
  const [newFieldValues, setNewFieldValues] = useState<{
    [key: string]: string;
  }>({});

  const requiredFields =
    section.type === "screening"
      ? sectionConfig[section.type].fields.filter(
          (f: any) => f.key !== "summary"
        )
      : sectionConfig[section.type].fields;

  const [summary, setSummary] = useState(section.summary || "");

  const handleFieldChange = (key: string, value: string) => {
    if (key === "summary") {
      setSummary(value);
    } else {
      setNewFieldValues((prevValues) => ({ ...prevValues, [key]: value }));
    }
  };

  useEffect(() => {
    if (summary !== "") {
      const updatedSections = sections.map((s) =>
        s.id === section.id ? { ...s, summary: summary } : s
      );
      setSections(updatedSections);
    }
  }, [summary]);

  const addFieldToSection = () => {
    const isValid = requiredFields.every(
      (field: any) =>
        newFieldValues[field.key] && newFieldValues[field.key].trim() !== ""
    );

    if (!isValid) {
      return;
    }

    const newGroup: GroupType = {
      id: section.groups.length + 1,
      fields: requiredFields.map((field: any, index: any) => ({
        ...field,
        id: section.groups.flatMap((g) => g.fields).length + index + 1,
        value: newFieldValues[field.key],
      })),
    };

    const updatedSections = sections.map((s) =>
      s.id === section.id
        ? { ...s, groups: [...s.groups, newGroup], summary }
        : s
    );

    setSections(updatedSections);
    setNewFieldValues({});
  };

  const handleRemove = (groupId: number) => {
    const updatedSections = sections.map((s) => {
      if (s.id === section.id) {
        const updatedGroups = s.groups.filter((group) => group.id !== groupId);
        return { ...s, groups: updatedGroups };
      }
      return s;
    });

    setSections(updatedSections);
  };

  return (
    <div>
      <h3>{section.label}</h3>
      {section.type === "screening" && (
        <div className="section__container resumes">
          <div className="form__container">
            <label>Resumen</label>
            <textarea
              value={summary}
              onChange={(e) => handleFieldChange("summary", e.target.value)}
              required={true}
              className="section__textarea"
              placeholder="Resumen"
            />
          </div>
        </div>
      )}
      <div className="section__container">
        {section.groups.map((group, index) => (
          <div className="section__container__details" key={index}>
            {group.fields.map((field) => (
              <Field
                key={field.id}
                field={field}
                section={section}
                updateSections={setSections}
                sections={sections}
              />
            ))}
            <button
              className="image-button"
              onClick={() => handleRemove(group.id)}
            >
              <Trash />
            </button>
          </div>
        ))}
        <div>
          {requiredFields.map((field: any) => (
            <div className="form__container" key={field.key}>
              <label>{field.label}</label>
              <input
                type={field.type === "textarea" ? "text" : field.type}
                placeholder={field.label}
                className="section__input"
                value={newFieldValues[field.key] || ""}
                onChange={(e) => handleFieldChange(field.key, e.target.value)}
              />
            </div>
          ))}
          <button className="section__btn" onClick={addFieldToSection}>
            +
          </button>
        </div>
      </div>
    </div>
  );
};

export default Section;
