import React, { useState } from "react";

const Menu = ({ sectionConfig, sections, addSection }: any) => {
  const [isAnimating, setIsAnimating] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const handleButtonClick = () => {
    if (isAnimating) return;
    setIsOpen(!isOpen);
    setIsAnimating(true);
  };

  const handleAnimationStart = () => {
    setIsAnimating(true);
  };

  const handleAnimationEnd = () => {
    setIsAnimating(false);
  };

  const handleSection = (type: any) => {
    addSection(type);
    setIsOpen(false);
  };

  return (
    <div className="sticky-menu-container">
      <div className={`inner-menu ${isOpen ? "open" : "closed"}`}>
        <ul className="menu-list">
          {Object.keys(sectionConfig).map((type) => (
            <li
              className={`menu-item ${
                sections.some((section: any) => section.type === type)
                  ? "checked"
                  : ""
              }`}
              key={type}
              onClick={() =>
                !sections.some((section: any) => section.type === type) &&
                handleSection(type)
              }
            >
              <span className="item-icon"></span>
              <span className="item-text">{sectionConfig[type].label}</span>
            </li>
          ))}
        </ul>
      </div>
      <div
        className="outer-button"
        onClick={handleButtonClick}
        onAnimationStart={handleAnimationStart}
        onAnimationEnd={handleAnimationEnd}
      >
        <div className="icon-container">
          <svg
            className={`icon close-icon ${isOpen ? "show" : "hide"}`}
            viewBox="0 0 92 92"
          >
            <path
              id="XMLID_732_"
              d="M70.7,64.3c1.8,1.8,1.8,4.6,0,6.4c-0.9,0.9-2,1.3-3.2,1.3c-1.2,0-2.3-0.4-3.2-1.3L46,52.4L27.7,70.7
                c-0.9,0.9-2,1.3-3.2,1.3s-2.3-0.4-3.2-1.3c-1.8-1.8-1.8-4.6,0-6.4L39.6,46L21.3,27.7c-1.8-1.8-1.8-4.6,0-6.4c1.8-1.8,4.6-1.8,6.4,0
                L46,39.6l18.3-18.3c1.8-1.8,4.6-1.8,6.4,0c1.8,1.8,1.8,4.6,0,6.4L52.4,46L70.7,64.3z"
            />
          </svg>
          <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 92 92"
            enableBackground="new 0 0 92 92"
            xmlSpace="preserve"
            className={`icon arrow-icon  ${isOpen ? "hide" : "show"}`}
          >
            <path d="M50.5255 76.2459C50.5255 78.7914 48.5456 80.7713 46 80.7713C44.7272 80.7713 43.6665 80.2764 42.818 79.4278C41.9695 78.5793 41.4745 77.5186 41.4745 76.2459L41.4745 50.3657L15.5944 50.3657C14.3216 50.3657 13.261 49.8708 12.4124 49.0222C11.5639 48.1737 11.0689 47.1131 11.0689 45.8403C11.0689 43.2947 13.0488 41.3148 15.5944 41.3148L41.4745 41.3148V15.4347C41.4745 12.8891 43.4544 10.9092 46 10.9092C48.5456 10.9092 50.5255 12.8891 50.5255 15.4347V41.3148L76.4056 41.3148C78.9512 41.3148 80.9311 43.2947 80.9311 45.8403C80.9311 48.3858 78.9512 50.3657 76.4056 50.3657L50.5255 50.3657L50.5255 76.2459Z" />
          </svg>
        </div>
      </div>
    </div>
  );
};

export default Menu;
