import React, { useState, useEffect } from "react";
import styled from "styled-components";
import toast from "react-hot-toast";

const TagInput = styled.input`
  margin-left: 10px;
  padding: 5px;
  font-size: 14px;
  width: 100%; // Ajusta el ancho según necesites
`;

const TagList = ({ cv, updateData }: any) => {
  const [tagInput, setTagInput] = useState("");

  useEffect(() => {
    if (cv.tags) {
      setTagInput(cv.tags.join(", "));
    }
  }, [cv.tags]);

  const handleTagInputKeyPress = async (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      e.preventDefault();
      const newTags = tagInput
        .split(",")
        .map((tag) => tag.trim())
        .filter((tag) => tag);
      if (JSON.stringify(newTags) !== JSON.stringify(cv.tags)) {
        await updateData("cvs", cv.id, { tags: newTags });
        toast.success("Tags actualizados");
      }
    }
  };

  return (
    <div className="tag-container">
      <div className="tag-list">
        <div>tags :</div>
        <div>
          <TagInput
            type="text"
            className="input-tag"
            value={tagInput}
            onChange={(e) => setTagInput(e.target.value)}
            onKeyPress={handleTagInputKeyPress}
            placeholder="Agregar tags"
          />
        </div>
      </div>
    </div>
  );
};

export default TagList;
