import React, { useState, useEffect } from "react";
import Section from "../section";
import { sectionConfig } from "../../config";
import { SectionType, FieldType } from "../../types";
import Menu from "../menu";
function CvMaker({ data, setCurrentData }: any) {
  const [sections, setSections] = useState<SectionType[]>(data || []);

  useEffect(() => {
    setCurrentData(sections);
  }, [sections]);

  useEffect(() => {
    if (data && data.length) {
      setSections(data);
    }
    if (!data) {
      setSections([]);
    }
  }, [data]);

  const addSection = (type: string) => {
    if (sections?.some((section) => section.type === type)) {
      return;
    }
    const newSection: SectionType = {
      id: sections.length + 1,
      type,
      label: sectionConfig[type].label,
      groups: [],
      summary: "",
    };
    setSections((prevSections) => [...prevSections, newSection]);
  };

  const handleRemoveSection = (sectionId: number) => {
    setSections((prevSections) =>
      prevSections.filter((section) => section.id !== sectionId)
    );
  };

  return (
    <div className="generator">
      {sections?.map((section) => (
        <div className="section" key={section.id}>
          <Section
            section={section}
            setSections={setSections}
            sections={sections}
          />
          <button
            className="section__delete"
            onClick={() => handleRemoveSection(section.id)}
          >
            Eliminar
          </button>
        </div>
      ))}

      <Menu
        sectionConfig={sectionConfig}
        sections={sections}
        addSection={addSection}
      />
    </div>
  );
}

export default CvMaker;
