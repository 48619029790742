import { StyleSheet, Font } from "@react-pdf/renderer";
Font.register({
  family: "Montserrat",
  fonts: [
    {
      src: require("../../fonts/Montserrat-Regular.ttf"),
      fontWeight: "normal",
    },
    {
      src: require("../../fonts/Montserrat-MediumItalic.ttf"),
      fontStyle: "italic",
    },
    {
      src: require("../../fonts/Montserrat-Medium.ttf"),
      fontStyle: "medium",
    },
    {
      src: require("../../fonts/Montserrat-SemiBold.ttf"),
      fontWeight: "semibold",
    },
  ],
});
export const styles: any = StyleSheet.create({
  page: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#1A1A1A",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    padding: "50px 25px",
    height: "100%",
    width: "100%",
  },
  container: {
    padding: "0px 10px",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    width: "100%",
    marginTop: "25px",
  },
  bodyContainer: {
    padding: "0px 35px",
  },
  section: {
    margin: "10px 0px",
    padding: 10,
    flexGrow: 0,
    flexDirection: "row",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  groupContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
    width: "100%",
    flexWrap: "wrap",
    gap: "15px 0px",
    flexGrow: 1,
    marginBottom: 0,
  },
  groupContainerCertifications: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
    width: "100%",
    flexWrap: "wrap",
    gap: "10px 0px",
    paddingRight: "15",
    flexGrow: 1,
    marginBottom: 0,
  },

  logoContainerMix: {
    position: "absolute",
  },
  child: {
    textAlign: "left",
    marginBottom: 10,
  },
  childFull: {
    backgroundColor: "red",
    display: "flex",
    flexDirection: "column",
  },
  dot: {
    flex: 1,
    textAlign: "left",
    margin: 10,
  },

  title: {
    fontFamily: "Montserrat",
    fontSize: 13,
    color: "#E8E8E8",
    marginBottom: 5,
    textTransform: "capitalize",
  },
  titleBold: {
    color: "#E8E8E8",
    fontFamily: "Montserrat",
    fontSize: 13,
    marginBottom: "15px",
    textTransform: "uppercase",
    fontWeight: "semibold",
  },

  mainPage: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
  },
  sectiontitle: {
    fontFamily: "Montserrat",
    fontSize: 13,
    textTransform: "uppercase",
    fontWeight: "semibold",
    lineHeight: 1,
    letterSpacing: "3px",
    color: "#fff",
    width: "100%",
    padding: "10px 35px",
    border: "1px solid #6A6A6A",
    borderRadius: "20px",
  },

  logoContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
  },
  headLine: {
    width: "80px",
    height: "45px",
    display: "flex",
    justifyContent: "flex-end",
    position: "absolute",
    top: "20px",
    left: "auto",
    right: "20px",
    textAlign: "right",
  },
  footerLine: {
    width: "80px",
    height: "50px",
    display: "flex",
    justifyContent: "center",
    position: "absolute",
    bottom: "20px",
    right: "auto",
    left: "50%",
    textAlign: "center",
  },

  summaryContainer: {
    display: "flex",
    flexDirection: "column",
    textAlign: "left",
    width: "100%",
    padding: "0px 10px",
  },

  summary: {
    fontFamily: "Montserrat",
    fontWeight: "normal",
    fontSize: 11,
    letterSpacing: "1px",
    color: "#fff",
    marginBottom: "25px",
  },
  groupDateIcon: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
    minWidth: "75px",
    padding: "0px 10px",
  },
  forcedLabel: {
    display: "flex",
    minWidth: "50px",
    flexWrap: "wrap",
    width: "50px",
    flexShrink: 0,
  },
  breakeable: {
    display: "flex",
    flex: 1,
    flexGrow: 1,
    flexDirection: "column",
  },
  workContainer: {
    display: "flex",
    flexDirection: "row",
    alignSelf: "stretch",
  },
  containerDescription: {
    maxWidth: "100%",
    width: "100%",
  },
  lineIcon: {
    display: "flex",
    backgroundColor: "#1a1a1a",
    minHeight: 30,
    marginLeft: -5,
  },
  line: {
    alignItems: "flex-start",
    marginLeft: -17,
    marginRight: 25,
    backgroundColor: "#e8e8e8",
    width: 2,
    maxWidth: 2,
    flexGrow: 1,
    height: "calc(100% - 30px)",
  },
});
