import { StyleSheet, Font } from "@react-pdf/renderer";

const GroupStyles: any = StyleSheet.create({
  default: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    width: "50%",
    padding: "0px 10px",
    marginBottom: 0,
    flexGrow: 0,
  },
  work: {
    display: "flex",
    flex: "0 0 100%",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    width: "100%",
    paddingRight: 25,
    marginBottom: 0,
    paddingBottom: 0,
  },
  education: {
    display: "flex",
    flex: "0 0 100%",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    width: "100%",
    marginBottom: 0,
  },
  certifications: {
    display: "flex",
    flex: "0 0 100%",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    width: "100%",
    padding: "0 10",
    marginBottom: "-10px",
  },
});

type getGroupType = {
  type: string;
};

export const getGroupStyles = ({ type }: getGroupType) => {
  console.log(type);
  return GroupStyles[type] || GroupStyles.default;
};
