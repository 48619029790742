import { StyleSheet, Font } from "@react-pdf/renderer";

Font.register({
  family: "Montserrat",
  fonts: [
    {
      src: require("../../../fonts/Montserrat-Regular.ttf"),
      fontWeight: "normal",
    },
    {
      src: require("../../../fonts/Montserrat-MediumItalic.ttf"),
      fontStyle: "italic",
    },
    {
      src: require("../../../fonts/Montserrat-Medium.ttf"),
      fontStyle: "medium",
    },
    {
      src: require("../../../fonts/Montserrat-SemiBold.ttf"),
      fontWeight: "semibold",
    },
  ],
});

const ValueStyles: any = StyleSheet.create({
  default: {
    fontFamily: "Montserrat",
    fontSize: 12,
    fontWeight: "medium",
    color: "#E8E8E8",
    marginTop: "-5px",
    marginBottom: 5,
  },
  education: {
    fontSize: 12,
    color: "#A28B57",
    textTransform: "uppercase",
    fontStyle: "italic",
    fontFamily: "Montserrat",
    marginTop: "-10px",
    marginBottom: "8px",
  },
  work: {
    display: "flex",
    flexDirection: "column",
    fontFamily: "Montserrat",
    fontSize: 12,
    fontWeight: "medium",
    color: "#E8E8E8",
    maxWidth: 350,
    textTransform: "uppercase",
    marginBottom: "-5px",
    lineHeight: 1,
  },
});

const LabelStyles: any = StyleSheet.create({
  default: {
    fontSize: 12,
    color: "#A28B57",
    textTransform: "uppercase",
    fontStyle: "italic",
    fontFamily: "Montserrat",
    marginTop: "0px",
  },
  education: {
    fontSize: 12,
    textTransform: "uppercase",
    fontWeight: "medium",
    fontFamily: "Montserrat",
    color: "#F0F0F0",
  },
  certifications: {
    fontSize: 12,
    fontWeight: "medium",
    fontFamily: "Montserrat",
    color: "#f0f0f0",
  },
});

const DateStyles: any = StyleSheet.create({
  default: {
    fontSize: 10,
    color: "#F0F0F0",
    textTransform: "uppercase",
    fontFamily: "Montserrat",
    marginTop: "-10px",
  },
});

const CompanyStyles: any = StyleSheet.create({
  default: {
    fontSize: 12,
    color: "#A28B57",
    textTransform: "uppercase",
    fontStyle: "italic",
    fontFamily: "Montserrat",
  },
});

const ShortenDate: any = StyleSheet.create({
  default: {
    fontSize: 12,
    color: "#A28B57",
    textTransform: "uppercase",
    fontStyle: "italic",
    fontFamily: "Montserrat",
  },
});

type getStyleType = {
  key: string;
  type: string;
};

const DescriptionStyles: any = StyleSheet.create({
  default: {
    maxWidth: "90%",
    fontFamily: "Montserrat",
    fontSize: 12,
    fontWeight: "normal",
    color: "#E8E8E8",
    marginTop: 0,
    marginBottom: 0,
  },
});

export const getStyles = ({ key, type }: getStyleType) => {
  if (key === "label") {
    return LabelStyles[type] || LabelStyles.default;
  }
  if (key === "value") {
    return ValueStyles[type] || ValueStyles.default;
  }
  if (key === "date") {
    return DateStyles[type] || DateStyles.default;
  }

  if (key === "company") {
    return CompanyStyles[type] || CompanyStyles.default;
  }
  if (key === "description") {
    return DescriptionStyles[type] || DescriptionStyles.default;
  }
  if (key === "shortendate") {
    return ShortenDate[type] || ShortenDate.default;
  }
};
