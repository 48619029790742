import React from "react";

import { Svg, Path } from "@react-pdf/renderer";

export const Dot = ({ style, color = "#B1925A" }: any) => (
  <Svg width="12" height="12" viewBox="0 0 12 12" fill="none" style={style}>
    <Path
      d="M6 9.16L2.85 6.01L6 2.86L9.15 6.01L6 9.16ZM6 0L0 6L6 12L12 6L6 0Z"
      fill={color}
    />
  </Svg>
);
