import React from "react";

import { Svg, Path } from "@react-pdf/renderer";

export const ZafirusLogo = ({ width, height }: any) => (
  <Svg width={width} height={height} viewBox="0 0 80 44" fill="none">
    <Path d="M33.6696 3.33405L39.531 14.206V3.33405H33.6696Z" fill="#B1925A" />
    <Path d="M45.845 3.33405H39.9836V14.206L45.845 3.33405Z" fill="#B1925A" />
    <Path
      d="M49.6095 3.33405H46.3425L40.5398 14.0964L49.6095 3.33405Z"
      fill="#B1925A"
    />
    <Path
      d="M33.1711 3.33405H29.9041L38.9738 14.0964L33.1711 3.33405Z"
      fill="#B1925A"
    />
    <Path
      d="M49.5384 2.92443L46.6184 0H43.4493L46.3702 2.92443H49.5384Z"
      fill="#B1925A"
    />
    <Path
      d="M33.7468 2.9245H39.1954L36.4712 0.19693L33.7468 2.9245Z"
      fill="#B1925A"
    />
    <Path
      d="M40.3185 2.92462H45.7412L43.0303 0.209518L40.3185 2.92462Z"
      fill="#B1925A"
    />
    <Path
      d="M32.8961 0L29.9753 2.92443H33.1177L36.0377 0H32.8961Z"
      fill="#B1925A"
    />
    <Path d="M42.6102 0H36.9037L39.7569 2.85674L42.6102 0Z" fill="#B1925A" />
    <Path
      d="M0.30695 35.2786L5.1443 19.3131H0V17.2635H7.90862L3.07128 33.2289H8.11324V35.2786H0.30695Z"
      fill="#EBEBEB"
    />
    <Path
      d="M18.5995 17.2634L26.5081 35.2786H24.2305L18.3949 21.9534C17.9856 22.8932 17.5327 23.9309 17.0381 25.0667C16.5425 26.2033 16.0398 27.3604 15.5283 28.5389C15.0167 29.7174 14.5086 30.8879 14.005 32.0495C13.5015 33.2111 13.0281 34.288 12.5842 35.2786H10.2807L18.2152 17.2634H18.5995Z"
      fill="#EBEBEB"
    />
    <Path
      d="M28.5384 35.2786V17.2634H35.3981V19.3131H30.5865V27.0781H35.3981V29.1277H30.5865V35.2777H28.5393L28.5384 35.2786Z"
      fill="#EBEBEB"
    />
    <Path
      d="M39.6304 35.2786V17.2634H41.6776V35.2786H39.6304Z"
      fill="#EBEBEB"
    />
    <Path
      d="M45.7736 35.2786V17.2893H48.5896C49.3912 17.2893 50.1511 17.4434 50.8671 17.7507C51.5841 18.058 52.2107 18.4811 52.7488 19.0192C53.2865 19.5572 53.7088 20.1808 54.0159 20.8898C54.323 21.5989 54.4765 22.3632 54.4765 23.1836C54.4765 24.0548 54.2599 24.9001 53.8246 25.7205C53.3893 26.541 52.7791 27.25 51.9945 27.8477C52.4383 28.9924 52.9198 30.2146 53.44 31.5124C53.9607 32.8112 54.4681 34.0663 54.9635 35.2795H52.5834L50.1262 28.7964C49.3752 29.036 48.6074 29.1295 47.8226 29.0779V35.2795H45.7754L45.7736 35.2786ZM52.4288 23.1836C52.4288 22.6545 52.322 22.1458 52.1083 21.6586C51.8947 21.1713 51.5881 20.7446 51.1866 20.3767C50.7856 20.0097 50.3031 19.7274 49.7401 19.5314C49.1767 19.3354 48.5371 19.2713 47.8199 19.339V27.0264C48.5531 27.0781 49.2105 27.0051 49.7908 26.8091C50.3707 26.6131 50.8532 26.3352 51.2373 25.9762C51.6209 25.6172 51.915 25.195 52.1198 24.7077C52.3245 24.2205 52.4268 23.7127 52.4268 23.1827L52.4288 23.1836Z"
      fill="#EBEBEB"
    />
    <Path
      d="M63.363 35.2787C62.646 35.2787 61.9682 35.1425 61.3282 34.869C60.6887 34.5964 60.1292 34.2241 59.6522 33.7546C59.1742 33.2852 58.799 32.7293 58.5257 32.0888C58.2524 31.4484 58.1163 30.7687 58.1163 30.0516V17.2645H60.1635V30.0516C60.1635 30.4962 60.2489 30.9104 60.4199 31.2943C60.5898 31.6782 60.8204 32.0158 61.1111 32.3062C61.4012 32.5966 61.7386 32.8229 62.1217 32.985C62.5063 33.1471 62.9193 33.2282 63.363 33.2282C63.8072 33.2282 64.1993 33.1471 64.5918 32.985C64.9843 32.8229 65.3247 32.5966 65.6159 32.3062C65.9061 32.0158 66.1361 31.6782 66.3071 31.2943C66.478 30.9104 66.5635 30.4962 66.5635 30.0516V17.2645H68.6107V30.0516C68.6107 30.7696 68.4735 31.4484 68.2012 32.0888C67.9284 32.7293 67.5528 33.2852 67.0748 33.7546C66.5973 34.2249 66.0383 34.5964 65.3988 34.869C64.7593 35.1425 64.081 35.2787 63.364 35.2787H63.363Z"
      fill="#EBEBEB"
    />
    <Path
      d="M71.3997 35.2786V33.2289H76.4417L71.6045 17.2634H79.513V19.3131H74.3687L79.2065 35.2786H71.3997Z"
      fill="#EBEBEB"
    />
    <Path
      d="M20.4631 33.2076L18.3945 31.1365L16.326 33.2076L18.3945 35.2786L20.4631 33.2076Z"
      fill="#EBEBEB"
    />
    <Path
      d="M1.64765 43.4729V40.5681H0.496887V40.2758H3.14458V40.5681H1.99382V43.4729H1.64765Z"
      fill="white"
    />
    <Path
      d="M7.6785 41.7054H9.40933V41.9931H7.6785V41.7054ZM7.71592 43.1806H9.68531V43.4729H7.36978V40.2758H9.61515V40.5681H7.71592V43.1806Z"
      fill="white"
    />
    <Path
      d="M15.5842 43.5003C15.3378 43.5003 15.1101 43.4607 14.9012 43.3816C14.6922 43.2994 14.5114 43.1852 14.3585 43.039C14.2057 42.8929 14.0857 42.7208 13.9983 42.5229C13.9141 42.325 13.8721 42.1088 13.8721 41.8744C13.8721 41.6399 13.9141 41.4237 13.9983 41.2258C14.0857 41.0279 14.2057 40.8559 14.3585 40.7097C14.5145 40.5636 14.6969 40.4509 14.9058 40.3718C15.1148 40.2895 15.3425 40.2484 15.5888 40.2484C15.8258 40.2484 16.0488 40.288 16.2578 40.3672C16.4667 40.4433 16.6429 40.559 16.7864 40.7143L16.5665 40.9289C16.4324 40.795 16.2843 40.6991 16.1221 40.6412C15.96 40.5803 15.7853 40.5499 15.5982 40.5499C15.4017 40.5499 15.2193 40.5833 15.0509 40.6504C14.8824 40.7143 14.7359 40.8072 14.6111 40.9289C14.4864 41.0477 14.3882 41.1878 14.3164 41.3492C14.2478 41.5075 14.2135 41.6825 14.2135 41.8744C14.2135 42.0662 14.2478 42.2428 14.3164 42.4042C14.3882 42.5625 14.4864 42.7026 14.6111 42.8243C14.7359 42.9431 14.8824 43.036 15.0509 43.103C15.2193 43.1669 15.4017 43.1989 15.5982 43.1989C15.7853 43.1989 15.96 43.1684 16.1221 43.1075C16.2843 43.0467 16.4324 42.9492 16.5665 42.8152L16.7864 43.0299C16.6429 43.1852 16.4667 43.3024 16.2578 43.3816C16.0488 43.4607 15.8243 43.5003 15.5842 43.5003Z"
      fill="white"
    />
    <Path
      d="M23.5916 40.2758H23.9331V43.4729H23.5916V40.2758ZM21.5427 43.4729H21.1965V40.2758H21.5427V43.4729ZM23.6243 41.9977H21.5052V41.7008H23.6243V41.9977Z"
      fill="white"
    />
    <Path
      d="M28.674 43.4729V40.2758H28.9593L31.2188 43.0527H31.0691V40.2758H31.4106V43.4729H31.1252L28.8705 40.696H29.0202V43.4729H28.674Z"
      fill="white"
    />
    <Path
      d="M37.5829 43.5003C37.3366 43.5003 37.1074 43.4607 36.8953 43.3816C36.6864 43.2994 36.5039 43.1852 36.348 43.039C36.1952 42.8898 36.0751 42.7178 35.9878 42.5229C35.9036 42.325 35.8615 42.1088 35.8615 41.8744C35.8615 41.6399 35.9036 41.4253 35.9878 41.2304C36.0751 41.0325 36.1952 40.8605 36.348 40.7143C36.5039 40.5651 36.6864 40.4509 36.8953 40.3718C37.1042 40.2895 37.3335 40.2484 37.5829 40.2484C37.8294 40.2484 38.057 40.2895 38.2659 40.3718C38.4749 40.4509 38.6558 40.5636 38.8086 40.7097C38.9645 40.8559 39.0846 41.0279 39.1688 41.2258C39.2561 41.4237 39.2998 41.6399 39.2998 41.8744C39.2998 42.1088 39.2561 42.325 39.1688 42.5229C39.0846 42.7208 38.9645 42.8929 38.8086 43.039C38.6558 43.1852 38.4749 43.2994 38.2659 43.3816C38.057 43.4607 37.8294 43.5003 37.5829 43.5003ZM37.5829 43.1989C37.7794 43.1989 37.9603 43.1669 38.1256 43.103C38.294 43.036 38.4391 42.9431 38.5606 42.8243C38.6854 42.7026 38.7821 42.5625 38.8507 42.4042C38.9193 42.2428 38.9536 42.0662 38.9536 41.8744C38.9536 41.6825 38.9193 41.5075 38.8507 41.3492C38.7821 41.1878 38.6854 41.0477 38.5606 40.9289C38.4391 40.8072 38.294 40.7143 38.1256 40.6504C37.9603 40.5833 37.7794 40.5499 37.5829 40.5499C37.3865 40.5499 37.2041 40.5833 37.0357 40.6504C36.8673 40.7143 36.7207 40.8072 36.5959 40.9289C36.4743 41.0477 36.3776 41.1878 36.3059 41.3492C36.2373 41.5075 36.203 41.6825 36.203 41.8744C36.203 42.0632 36.2373 42.2382 36.3059 42.3996C36.3776 42.561 36.4743 42.7026 36.5959 42.8243C36.7207 42.9431 36.8673 43.036 37.0357 43.103C37.2041 43.1669 37.3865 43.1989 37.5829 43.1989Z"
      fill="white"
    />
    <Path
      d="M43.7478 43.4729V40.2758H44.094V43.1806H45.9278V43.4729H43.7478Z"
      fill="white"
    />
    <Path
      d="M51.5424 43.5003C51.2959 43.5003 51.0663 43.4607 50.8547 43.3816C50.6455 43.2994 50.4631 43.1852 50.3071 43.039C50.1545 42.8898 50.0343 42.7178 49.9468 42.5229C49.8629 42.325 49.8206 42.1088 49.8206 41.8744C49.8206 41.6399 49.8629 41.4253 49.9468 41.2304C50.0343 41.0325 50.1545 40.8605 50.3071 40.7143C50.4631 40.5651 50.6455 40.4509 50.8547 40.3718C51.0634 40.2895 51.2924 40.2484 51.5424 40.2484C51.7883 40.2484 52.0164 40.2895 52.2251 40.3718C52.4343 40.4509 52.6152 40.5636 52.7677 40.7097C52.9237 40.8559 53.044 41.0279 53.1279 41.2258C53.2154 41.4237 53.2591 41.6399 53.2591 41.8744C53.2591 42.1088 53.2154 42.325 53.1279 42.5229C53.044 42.7208 52.9237 42.8929 52.7677 43.039C52.6152 43.1852 52.4343 43.2994 52.2251 43.3816C52.0164 43.4607 51.7883 43.5003 51.5424 43.5003ZM51.5424 43.1989C51.7386 43.1989 51.9195 43.1669 52.085 43.103C52.2534 43.036 52.398 42.9431 52.5197 42.8243C52.6445 42.7026 52.7414 42.5625 52.8099 42.4042C52.8785 42.2428 52.9128 42.0662 52.9128 41.8744C52.9128 41.6825 52.8785 41.5075 52.8099 41.3492C52.7414 41.1878 52.6445 41.0477 52.5197 40.9289C52.398 40.8072 52.2534 40.7143 52.085 40.6504C51.9195 40.5833 51.7386 40.5499 51.5424 40.5499C51.3456 40.5499 51.1632 40.5833 50.9948 40.6504C50.8263 40.7143 50.6797 40.8072 50.555 40.9289C50.4333 41.0477 50.3369 41.1878 50.2648 41.3492C50.1963 41.5075 50.162 41.6825 50.162 41.8744C50.162 42.0632 50.1963 42.2382 50.2648 42.3996C50.3369 42.561 50.4333 42.7026 50.555 42.8243C50.6797 42.9431 50.8263 43.036 50.9948 43.103C51.1632 43.1669 51.3456 43.1989 51.5424 43.1989Z"
      fill="white"
    />
    <Path
      d="M59.1434 43.5003C58.8934 43.5003 58.6629 43.4607 58.4507 43.3816C58.242 43.2994 58.0596 43.1852 57.9036 43.039C57.7506 42.8929 57.6308 42.7208 57.5434 42.5229C57.4589 42.325 57.4171 42.1088 57.4171 41.8744C57.4171 41.6399 57.4589 41.4237 57.5434 41.2258C57.6308 41.0279 57.7521 40.8559 57.9081 40.7097C58.0641 40.5636 58.2465 40.4509 58.4557 40.3718C58.6673 40.2895 58.8984 40.2484 59.1478 40.2484C59.3973 40.2484 59.6248 40.2865 59.8306 40.3626C60.0398 40.4388 60.2176 40.5544 60.3642 40.7097L60.1491 40.9244C60.0114 40.7904 59.8604 40.6945 59.6949 40.6367C59.5299 40.5788 59.3521 40.5499 59.1617 40.5499C58.959 40.5499 58.7722 40.5833 58.6007 40.6504C58.4323 40.7143 58.2837 40.8072 58.156 40.9289C58.0313 41.0477 57.9329 41.1878 57.8614 41.3492C57.7928 41.5075 57.7585 41.6825 57.7585 41.8744C57.7585 42.0632 57.7928 42.2382 57.8614 42.3996C57.9329 42.561 58.0313 42.7026 58.156 42.8243C58.2837 42.9431 58.4323 43.036 58.6007 43.103C58.7722 43.1669 58.9575 43.1989 59.1573 43.1989C59.3441 43.1989 59.5205 43.1715 59.686 43.1167C59.8544 43.0619 60.0084 42.969 60.1491 42.8381L60.3453 43.0938C60.1893 43.2278 60.007 43.3298 59.7983 43.3998C59.5891 43.4668 59.3709 43.5003 59.1434 43.5003ZM60.0134 43.0527V41.8744H60.3453V43.0938L60.0134 43.0527Z"
      fill="white"
    />
    <Path d="M64.9973 43.4729V40.2758H65.3436V43.4729H64.9973Z" fill="white" />
    <Path
      d="M70.3896 41.7054H72.1208V41.9931H70.3896V41.7054ZM70.4273 43.1806H72.3965V43.4729H70.081V40.2758H72.3265V40.5681H70.4273V43.1806Z"
      fill="white"
    />
    <Path
      d="M77.7853 43.5003C77.5419 43.5003 77.3083 43.4623 77.0837 43.3861C76.8621 43.307 76.6907 43.2065 76.5689 43.0847L76.7046 42.8243C76.8199 42.934 76.9744 43.0268 77.1677 43.103C77.3645 43.176 77.5702 43.2126 77.7853 43.2126C77.9911 43.2126 78.158 43.1882 78.2857 43.1395C78.4169 43.0877 78.5118 43.0192 78.5709 42.934C78.6335 42.8487 78.6648 42.7543 78.6648 42.6508C78.6648 42.526 78.6271 42.4255 78.5525 42.3494C78.4805 42.2732 78.3856 42.2139 78.2673 42.1712C78.1486 42.1256 78.0174 42.086 77.8743 42.0525C77.7307 42.019 77.5871 41.984 77.444 41.9475C77.3004 41.9079 77.1677 41.8561 77.046 41.7921C76.9277 41.7282 76.8308 41.6445 76.7563 41.541C76.6842 41.4344 76.6484 41.2958 76.6484 41.1254C76.6484 40.967 76.6907 40.8224 76.7747 40.6915C76.8621 40.5575 76.9948 40.4509 77.1727 40.3718C77.3501 40.2895 77.5781 40.2484 77.8554 40.2484C78.0393 40.2484 78.2221 40.2743 78.403 40.3261C78.5838 40.3748 78.7394 40.4433 78.8706 40.5316L78.7538 40.8011C78.6132 40.7097 78.4636 40.6427 78.3046 40.6001C78.1486 40.5575 77.9975 40.5362 77.8509 40.5362C77.6542 40.5362 77.4922 40.5621 77.3645 40.6138C77.2363 40.6656 77.1414 40.7356 77.0788 40.8239C77.0196 40.9092 76.9898 41.0066 76.9898 41.1162C76.9898 41.241 77.0261 41.3415 77.0976 41.4176C77.1727 41.4938 77.2691 41.5531 77.3878 41.5958C77.5091 41.6384 77.6417 41.6765 77.7853 41.71C77.9289 41.7434 78.0706 41.78 78.2112 41.8196C78.3543 41.8592 78.4855 41.9109 78.6037 41.9749C78.7255 42.0358 78.8224 42.118 78.8939 42.2215C78.9689 42.325 79.0062 42.4605 79.0062 42.628C79.0062 42.7833 78.9625 42.9279 78.875 43.0619C78.7881 43.1928 78.6539 43.2994 78.473 43.3816C78.2952 43.4607 78.0661 43.5003 77.7853 43.5003Z"
      fill="white"
    />
  </Svg>
);
