import React, { useState, useEffect } from "react";
import { Page, Text, View, Document } from "@react-pdf/renderer";

import { ZafirusLogo } from "./icons/logo";
import { Dot } from "./icons/dot";

import { styles } from "./styles";
import { getStyles } from "../../utils/styles/text";
import { getGroupStyles } from "../../utils/styles/layout";

const TextField = ({ field, type, breaks }: any) => {
  if (breaks) {
    const segments = field.value.toLowerCase().split(/(?<!-\s)\s+/);
    return (
      <View style={styles.breakeable}>
        {segments.map((segment: any, index: any) => (
          <Text key={index} style={getStyles({ key: "shortendate", type })}>
            {segment}
            {index < segments.length - 1 && <Text>{"\n"}</Text>}
          </Text>
        ))}
      </View>
    );
  } else {
    return (
      <Text style={getStyles({ key: field.key, type })}>{field.value}</Text>
    );
  }
};

const MyDocument = ({ data }: any) => {
  const [currentData, setCurrentData] = useState(data);
  useEffect(() => {
    setCurrentData(data);
  }, [data]);

  const LogoSmall = ({ w = 80, h = 50 }: any) => (
    <ZafirusLogo width={w} height={h} />
  );

  const ShowFields = ({ group, type, exclude }: any) => {
    const array = exclude
      ? group.fields.filter((item: any) => !exclude.includes(item.label))
      : group.fields;

    return array.map((field: any, fieldIndex: any) => (
      <View style={styles.child} key={fieldIndex}>
        <TextField field={field} type={type} />
      </View>
    ));
  };

  return (
    <Document>
      <Page size="A4" style={styles.page} wrap>
        <View style={styles.mainPage}>
          <LogoSmall w="168" h="80" />
        </View>
        <View
          style={styles.headLine}
          render={(page: any) => {
            if (page?.pageNumber === 2 && page?.totalPages !== 2) {
              return <LogoSmall w="80" h="50" key={page?.pageNumber} />;
            }
          }}
        />
        <View
          style={styles.footerLine}
          render={(page: any) => {
            if (page?.pageNumber === page?.totalPages) {
              return <LogoSmall w="80" h="50" key={page?.pageNumber} />;
            }
          }}
        />
        {currentData.map((page: any, pageIndex: any) => {
          const { groups, type, summary, label } = page || null;
          return (
            <>
              <View style={styles.container} wrap>
                <View style={styles.section} wrap={false}>
                  <Text style={styles.sectiontitle}>{label}</Text>
                </View>

                <View style={styles.bodyContainer} wrap>
                  {type === "screening" && summary && (
                    <View style={styles.summaryContainer}>
                      <Text style={styles.titleBold}>RESUMEN</Text>
                      <Text style={styles.summary}>{summary}</Text>
                    </View>
                  )}
                  <View
                    style={
                      type === "certifications"
                        ? styles.groupContainerCertifications
                        : styles.groupContainer
                    }
                  >
                    {groups.map((group: any, groupIndex: any) => (
                      <View
                        style={getGroupStyles({ type })}
                        key={groupIndex}
                        wrap={false}
                      >
                        {type === "education" && (
                          <>
                            <View>
                              <Dot style={{ marginRight: 35 }} />
                            </View>
                            <View>
                              <ShowFields group={group} type={type} />
                            </View>
                          </>
                        )}
                        {type === "work" && (
                          <>
                            <View style={styles.groupDateIcon}>
                              <TextField
                                style={styles.forcedLabel}
                                field={group.fields[0]}
                                type={type}
                                breaks={true}
                              />
                            </View>
                            <View style={styles.workContainer}>
                              <View style={styles.line}>
                                <View style={styles.lineIcon}>
                                  <Dot />
                                </View>
                              </View>
                              <View style={styles.containerDescription}>
                                <ShowFields
                                  group={group}
                                  type={type}
                                  exclude={["Date"]}
                                />
                              </View>
                            </View>
                          </>
                        )}

                        {type === "certifications" && (
                          <>
                            <View>
                              <Dot
                                style={{ marginRight: 10, marginTop: 3 }}
                                color={"#fff"}
                              />
                            </View>
                            <View>
                              <ShowFields group={group} type={type} />
                            </View>
                          </>
                        )}
                        {type !== "education" &&
                          type !== "work" &&
                          type !== "certifications" && (
                            <ShowFields group={group} type={type} />
                          )}
                      </View>
                    ))}
                  </View>
                </View>
              </View>
            </>
          );
        })}
      </Page>
    </Document>
  );
};

export default MyDocument;
