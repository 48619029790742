
export const sectionConfig: any = {
  personal: {
    label: 'Información Personal',
    fields: [
      { key: 'label', label: 'Label', type: 'text', required: true },
      { key: 'value', label: 'Value', type: 'text', required: true }
    ]
  },
  screening: {
    label: 'Screening',
    fields: [
      { key: 'summary', label: 'Resumen', type: 'textarea', required: true, unique: true },
      { key: 'label', label: 'Label', type: 'text', required: false },
      { key: 'value', label: 'Value', type: 'text', required: true }
    ]
  },
  work: {
    label: 'Experiencia Laboral',
    fields: [
      { key: 'date', label: 'Date', type: 'text', required: true },
      { key: 'value', label: 'Value', type: 'text', required: true },
      { key: 'company', label: 'Company', type: 'text', required: true },
      { key: 'description', label: 'Description', type: 'textarea', required: true }
    ]
  },
  aclarations: {
    label: 'Aclaraciones',
    fields: [
      { key: 'label', label: 'Label', type: 'text', required: true },
      { key: 'value', label: 'Value', type: 'text', required: true },
    ]
  },
  education: {
    label: 'Formación Académica',
    fields: [
      { key: 'label', label: 'Label', type: 'text', required: true },
      { key: 'value', label: 'Value', type: 'text', required: true },
      { key: 'date', label: 'Date', type: 'text', required: true }
    ]
  },
  certifications: {
    label: 'Cursos y Certificaciones',
    fields: [
      { key: 'label', label: 'Label', type: 'text', required: true },
    ]
  }
};
