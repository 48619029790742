import { useState, useEffect } from "react";
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  onAuthStateChanged,
  signOut,
  User,
  getIdTokenResult,
} from "firebase/auth";
import { app } from "../config/firebase";
import toast from "react-hot-toast";

const useFirebaseAuth = () => {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const auth = getAuth(app);

  const allowedDomain = "zafirus.tech";
  const allowedEmail = "alvaro.e.ricotta@gmail.com";

  const signInWithGoogle = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      const tokenResult = await getIdTokenResult(result.user);
      const email = result.user.email;

      if (
        email &&
        (email.endsWith(`@${allowedDomain}`) || email === allowedEmail)
      ) {
        setUser(result.user);
        toast.success(`${email} ha iniciado sesion`);
      } else {
        await signOut(auth);
        toast.success(`Acceso denegado. Solo se permiten emails zafirus.tech`);
      }
    } catch (error) {
      toast.success(`Error al loguearse ${error}`);
    }
  };

  const signOutUser = async () => {
    try {
      await signOut(auth);
      setUser(null);
    } catch (error) {
      console.error("Error signing out: ", error);
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const tokenResult = await getIdTokenResult(user);
        const email = user.email;

        if (
          email &&
          (email.endsWith(`@${allowedDomain}`) || email === allowedEmail)
        ) {
          setUser(user);
        } else {
          await signOut(auth);
          setUser(null);
          toast.success(
            `Acceso denegado. Solo se permiten emails zafirus.tech`
          );
        }
      } else {
        setUser(null);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, [auth]);

  useEffect(() => {
    const handleBeforeUnload = async (event: BeforeUnloadEvent) => {
      await signOutUser();
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  return {
    user,
    loading,
    signInWithGoogle,
    signOutUser,
  };
};

export default useFirebaseAuth;
