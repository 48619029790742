import {
  db,
  collection,
  doc,
  setDoc,
  updateDoc,
  deleteDoc,
  getDocs,
} from "../config/firebase";

const useFirestore = () => {
  const saveData = async (collectionPath: any, data: any) => {
    const docRef = doc(collection(db, collectionPath));
    await setDoc(docRef, {
      ...data,
      createdAt: new Date(),
    });
    return docRef.id;
  };

  const updateData = async (collectionPath: any, docId: any, data: any) => {
    const docRef = doc(db, collectionPath, docId);
    await updateDoc(docRef, data);
    return docRef.id;
  };

  const deleteData = async (collectionPath: any, docId: any) => {
    const docRef = doc(db, collectionPath, docId);
    await deleteDoc(docRef);
  };

  const getAllData = async (collectionPath: string) => {
    const querySnapshot = await getDocs(collection(db, collectionPath));
    const data = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    return data;
  };

  return {
    saveData,
    updateData,
    deleteData,
    getAllData,
  };
};

export default useFirestore;
