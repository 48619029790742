import React, { useState, useEffect } from "react";
import "./App.css";
import CvMaker from "./component/generator";
import MyDocument from "./component/pdfviewer";
import { PDFViewer, pdf } from "@react-pdf/renderer";
import useFirestore from "./hooks/useFirestore";
import useFirebaseAuth from "./hooks/useFirebaseAuth";

import { ReactComponent as Loader } from "./images/load.svg";
import { ReactComponent as Close } from "./images/close.svg";
import { ReactComponent as Download } from "./images/download.svg";
import { ReactComponent as Logo } from "./images/logo.svg";
import { ReactComponent as Google } from "./images/google.svg";

import { saveAs } from "file-saver";

import { CvList } from "./component/CvList";
import toast, { Toaster } from "react-hot-toast";

function App() {
  const { saveData, updateData, getAllData } = useFirestore();
  const { user, signInWithGoogle } = useFirebaseAuth();
  const [currentData, setCurrentData] = useState<any>(null);
  const dataIsAvailable = currentData && currentData.length > 0;
  const hasData = currentData && currentData.length !== 0;

  const [name, setName] = useState("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [docId, setDocId] = useState<any>(null);
  const [allCvs, setAllCvs] = useState<any>(null);

  const handleSave = async () => {
    setIsLoading(true);
    setIsUpdating(true);
    if (!name.trim()) {
      toast.error("Por favor, ingresa un nombre antes de guardar.");

      return;
    }
    if (!hasData) {
      toast.error("No puedes guardar un CV sin información.");

      return;
    }
    if (docId) {
      await updateData("cvs", docId, { name, data: currentData });
      toast.success(`CV actualizado con éxito! ID del documento: ${docId}`);
    } else {
      const newDocId = await saveData("cvs", { name, data: currentData });
      setDocId(newDocId);
      toast.success(`CV guardado con éxito! ID del documento: ${newDocId}`);
    }

    setIsLoading(false);
    setIsUpdating(false);
  };

  const fetchData = async () => {
    const collectionPath = "cvs";
    const data = await getAllData(collectionPath);
    return data;
  };

  const handleFinishEdit = () => {
    setCurrentData(null);
    setName("");
    setDocId(null);
  };

  const handleDownload = async () => {
    console.log(currentData);
    const blob = await pdf(<MyDocument data={currentData} />).toBlob();
    saveAs(blob, `${name} - Zafirus Tech.pdf`);
    toast.success(`Descarga iniciada: ${name} - Zafirus Tech.pdf`);
  };

  useEffect(() => {
    const fetchAndSetData = async () => {
      const defaultData = await fetchData();
      setAllCvs(defaultData);
    };
    fetchAndSetData();
    setIsUpdating(false);
  }, [isUpdating]);

  return (
    <>
      {user ? (
        <div>
          <div>
            <Toaster />
          </div>
          {!docId && !hasData && (
            <CvList
              data={allCvs}
              setIsUpdating={setIsUpdating}
              setCurrentData={setCurrentData}
              setDocId={setDocId}
              setName={setName}
            />
          )}

          <div className="main">
            <CvMaker data={currentData} setCurrentData={setCurrentData} />
            <div className="pdf-container">
              {dataIsAvailable && (
                <PDFViewer width="100%" height="100%" showToolbar={false}>
                  <MyDocument data={currentData} />
                </PDFViewer>
              )}
            </div>
            {hasData && (
              <div className="save-file">
                <input
                  value={name}
                  placeholder="Nombre del Candidato"
                  onChange={(e) => setName(e.target.value)}
                />
                <button onClick={handleSave} className="save-button">
                  {docId ? "Actualizar" : "Crear CV"} {isLoading && <Loader />}
                </button>
                <button className="image-button" onClick={handleDownload}>
                  <Download width="15" height="20" />
                </button>
                <button className="image-button" onClick={handleFinishEdit}>
                  <Close width="20" height="20" />
                </button>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="login-page">
          <div>
            <Logo />
            <button className="google-button" onClick={signInWithGoogle}>
              <Google /> Sign in with Google
            </button>
          </div>
        </div>
      )}
    </>
  );
}

export default App;
