import React from "react";
import { FieldType, SectionType } from "../../types";

interface FieldProps {
  field: FieldType;
  section: SectionType;
  updateSections: React.Dispatch<React.SetStateAction<SectionType[]>>;
  sections: SectionType[];
}

const Field: React.FC<FieldProps> = ({
  field,
  section,
  updateSections,
  sections,
}) => {
  const handleChange = (value: string) => {
    const updatedSections = sections.map((currentSection) =>
      currentSection.id === section.id
        ? {
            ...currentSection,
            groups: currentSection.groups.map((group) => ({
              ...group,
              fields: group.fields.map((currentField) =>
                currentField.id === field.id
                  ? { ...currentField, value: value }
                  : currentField
              ),
            })),
          }
        : currentSection
    );
    updateSections(updatedSections);
  };

  return (
    <div className={`preview-${field.label.toLowerCase()}`}>
      <label>{field.label}</label>
      {field.type === "text" && (
        <input
          type="text"
          value={field.value}
          onChange={(e) => handleChange(e.target.value)}
          required={field.required}
        />
      )}
      {field.type === "textarea" && (
        <textarea
          onChange={(e) => handleChange(e.target.value)}
          value={field.value}
          required={field.required}
        />
      )}
    </div>
  );
};

export default Field;
